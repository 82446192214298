import { Box, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { theme } from 'src/theme/ThemeProvider'
import { IconButton } from 'gatsby-theme-material-ui'
import { Icon } from '@/components/elements/icon'
import { useIsFirefox } from '@/utils/hooks'
import PairShadow from '../../../assets/pairs-shadow.svg'

interface IPairsListProps {
  pairs: string[]
}
const PairsList = ({ pairs }: IPairsListProps) => {
  const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null)
  const [scrollbarRef, setScrollbarRef] = useState<HTMLDivElement | null>(null)

  const [buttonsAreVisable, setButtonsAreVisable] = useState(true)
  const handleScrollbarRef = useCallback((node) => {
    setScrollbarRef(node)
  }, [])
  const handlecontentRef = useCallback((node) => {
    setContentRef(node)
  }, [])
  useEffect(() => {
    if (!contentRef || !scrollbarRef) return
    const buttonsVisable = scrollbarRef?.clientHeight < contentRef?.clientHeight
    setButtonsAreVisable(buttonsVisable)
  }, [contentRef, scrollbarRef])

  const isFirefox = useIsFirefox()

  return (
    <Box
      sx={{
        height: '700px',
        position: 'relative',
        paddingY: '22px',
        bgcolor: theme.palette.background.baseLight,
      }}
    >
      {buttonsAreVisable && (
        <IconButton
          onClick={() => {
            if (scrollbarRef) {
              scrollbarRef.scrollTop = 0
            }
          }}
          sx={{
            width: '48px',
            display: buttonsAreVisable ? 'flex' : 'none',
            justifyContent: 'center',
            textAlign: { md: 'left', xs: 'center' },
            padding: 1.2,
            minWidth: 0,
            position: 'absolute',
            top: '32px',
            right: isFirefox ? 'calc(3% + 1px)' : 'calc(3%)',
            zIndex: '5000',
          }}
        >
          <Icon icon="arrowTop" />
        </IconButton>
      )}
      {buttonsAreVisable && (
        <IconButton
          onClick={() => {
            if (scrollbarRef) {
              scrollbarRef.scrollTop = scrollbarRef?.scrollHeight
            }
          }}
          sx={{
            width: '48px',
            display: buttonsAreVisable ? 'flex' : 'none',
            justifyContent: 'center',
            textAlign: { md: 'left', xs: 'center' },
            padding: 1.2,
            minWidth: 0,
            position: 'absolute',
            bottom: '32px',
            right: isFirefox ? 'calc(3% + 1px)' : 'calc(3%)',
            zIndex: '5000',
          }}
        >
          <Icon icon="arrowBottom" />
        </IconButton>
      )}
      <Box
        ref={handleScrollbarRef}
        //@ts-ignore
        sx={{
          width: { md: 'calc(90% - 1px)', lg: 'calc(90.8%)' },
          height: 'calc(80% - -60px)',
          position: 'absolute',
          top: 'calc(10% + -30px)',
          overflowY: buttonsAreVisable ? 'scroll' : 'none',
          scrollbarColor: `${theme.palette.success.light}`, //firefox
          scrollbarWidth: '20px', //firefox
          scrollBehavior: 'smooth',
          '&::-webkit-scrollbar': {
            display: buttonsAreVisable ? 'block' : 'none',
            background: 'transparent',
            width: '8px', // Width of the scrollbar
            borderRadius: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: theme.palette.success.main,
            borderRadius: '10px', // Adjust border radius to match scrollbar background
            border: '1px solid white',
          },
          '&::-webkit-scrollbar-track': {
            marginTop: '60px',
            marginBottom: '60px',
            background: 'white',
            borderRadius: '6px',
          },

          ml: '14px',
        }}
      >
        <Box ref={handlecontentRef} sx={{ width: '94%', m: 'auto' }}>
          <ul
            style={{
              listStyle: 'none',
              padding: 0,
              margin: 0,
            }}
          >
            {pairs?.map((pair) => (
              <li>
                <Typography
                  sx={{
                    fontSize: '20px',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2,
                  }}
                >
                  {pair}
                </Typography>
              </li>
            ))}
          </ul>
        </Box>
      </Box>
      {/* <Box sx={{ position: 'absolute', bottom: '30px', width: '100%' }}>
        <PairShadow />
      </Box> */}
    </Box>
  )
}

export default PairsList
