import { Box, Grid, Typography } from '@mui/material'
import { Strapi__Component_Pricing_Pricing_Page_Legend, Strapi__Component_Pricing_Tiers } from 'gatsby-graphql'
import React from 'react'
import Tier from './tier'

interface ITiersProps {
  tiers: Array<Strapi__Component_Pricing_Tiers>
  PricingPageLegend: Array<Strapi__Component_Pricing_Pricing_Page_Legend>
}

const Tiers = (props: ITiersProps) => {
  return (
    <Box mb={'-100px'}>
      <Grid container gap={4} mt={'-50px'}>
        {props?.tiers?.map((tier, index) => (
          <Grid item xs={12} md={11.5 / props.tiers.length} marginTop={'128px'}>
            <Tier tier={tier} index={index} totalLength={props?.tiers?.length} />
          </Grid>
        ))}
      </Grid>
      <Grid item sx={{ marginBottom: '120px', justifyContent: 'center', width: '100%', py: 3 }}>
        {props?.PricingPageLegend?.map((item) => (
          <Typography sx={{ width: { sm: '380px', xs: '100%' }, m: 'auto', fontSize: '12px' }}>
            {item.LegendParagraph}
          </Typography>
        ))}
      </Grid>
    </Box>
  )
}

export default Tiers
